import ApiClient from '@/services/network/ApiClient';
import {
  GetLaunchpadListRequest,
  GetLaunchListResponse,
  GetLaunchpadInfoRequest,
  GetLaunchpadInfoResponse,
} from './types';
import { getQueryParams } from '../getQueryParams';

export default class LaunchpadService {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getLaunchpadList(data: GetLaunchpadListRequest): Promise<GetLaunchListResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetLaunchListResponse>(`/launchpad/list?${queryParams}`);
  }

  async getLaunchpadInfo(data: GetLaunchpadInfoRequest): Promise<GetLaunchpadInfoResponse> {
    const { name, ...props } = data;
    const queryParams = getQueryParams(props as any);
    return await this.apiClient.get<GetLaunchpadInfoResponse>(`/launchpad/get/${name}?${queryParams}`);
  }
}
