import ApiClient from '@/services/network/ApiClient';
import {
  GetErc20ListRequest,
  GetErc20ListResponse,
  GetOwnerRequest,
  GetOwnerResponse,
  GetErc20InfoRequest,
  GetErc20InfoResponse,
  GetHoldersRequest,
  GetOwnerInfoResponse,
  GetTransfersRequest,
  GetTransfersInfoResponse,
  GetTokenSelectonResponse,
  GetEthscribeRequest,
  GetEthscribeDataResponse,
  GetRecentEthsRequest,
  GetRecentEthsListResponse,
  GetTransactionStatsResponse,
  GetRecentTransactionsRequest,
  GetRecentTransactionListResponse,
  GetSearchedEthsRequest,
  GetCategoryHoldersRequest,
  GetErc20sListResponse,
  getErc20FactoryAddressResponse,
  AssetItem,
} from './types';
import { getQueryParams } from '../getQueryParams';
import services from '..';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { ISwapTokenInfoItem } from '../marketpalce/types';
import { SwapLastPriceItem } from '../facet/types';

export default class EthscriptionsService {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getOwner(data: GetOwnerRequest): Promise<GetOwnerResponse> {
    const { address, ...props } = data;
    const queryParams = getQueryParams(props as any);
    return await this.apiClient.get<GetOwnerResponse>(`/ethscriptions/address/${address}?${queryParams}`);
  }

  async getErc20(data: GetErc20ListRequest): Promise<GetErc20ListResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetErc20ListResponse>(`/ethscriptions/erc20?${queryParams}`);
  }

  async getErc20Info(data: GetErc20InfoRequest): Promise<GetErc20InfoResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetErc20InfoResponse>(`/ethscriptions/erc20/info?${queryParams}`);
  }

  async getHoldersInfo(data: GetHoldersRequest): Promise<GetOwnerInfoResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetOwnerInfoResponse>(`/ethscriptions/erc20/holders?${queryParams}`);
  }

  async getCategoryHoldersInfo(data: GetCategoryHoldersRequest): Promise<GetOwnerInfoResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetOwnerInfoResponse>(`/markets/collections/holders?${queryParams}`);
  }

  async getTransfersInfo(data: GetTransfersRequest): Promise<GetTransfersInfoResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetTransfersInfoResponse>(`/ethscriptions/erc20/transfers?${queryParams}`);
  }

  async getTokenSelections(owner: string): Promise<GetTokenSelectonResponse> {
    return await this.apiClient.get<GetTokenSelectonResponse>(`/ethscriptions/collections/${owner}`);
  }

  async getEthscribe(data: GetEthscribeRequest): Promise<GetEthscribeDataResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetEthscribeDataResponse>(`/ethscriptions/erc20/ethscribe?${queryParams}`);
  }
  async getLatestEths(data: GetRecentEthsRequest): Promise<GetRecentEthsListResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetRecentEthsListResponse>(`/ethscriptions/latest?${queryParams}`);
  }
  async getLatestTransactions(data: GetRecentTransactionsRequest): Promise<GetRecentTransactionListResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetRecentTransactionListResponse>(
      `/ethscriptions/transactions/latest?${queryParams}`,
    );
  }
  async getTransactionStats(): Promise<GetTransactionStatsResponse> {
    return await this.apiClient.get<GetTransactionStatsResponse>(`/ethscriptions/transactions/stats`);
  }
  async getSearchedEths(data: GetSearchedEthsRequest): Promise<GetRecentEthsListResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetRecentEthsListResponse>(`/ethscriptions/search?${queryParams}`, {
      timeout: 20 * 1000,
    });
  }
  async getErc20sList(data: GetErc20ListRequest): Promise<GetErc20sListResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetErc20sListResponse>(`/ethscriptions/erc20s?${queryParams}`);
  }
  async getErc20FactoryAddress(): Promise<getErc20FactoryAddressResponse> {
    return await this.apiClient.get<getErc20FactoryAddressResponse>('/ethscriptions/erc20s/factory');
  }

  async getAssetList(data: { owner: string }): Promise<AssetItem[]> {
    const { owner } = data;

    const collectionDetail = await services.marketplace.getCollectionDetail({
      category: 'token',
      collectionName: 'erc-20 eths',
    });

    const [etchAssset, facetAsset] = await Promise.all([
      services.marketplace.getCollectionOwnerList({
        category: 'token',
        tokenQuery: '',
        address: owner,
      }),
      services.facet.getPairs({
        router: collectionDetail.data.collections.facetStat.routerAddress,
        account: owner,
      }),
    ]);

    const assetList: AssetItem[] = [];

    let facetAssetList = Object.values(facetAsset);
    facetAssetList = facetAssetList.filter((item) => new BigNumber(item.balance).gt(0));

    const tokenInfo = await services.marketplace.getSwapTokensInfo({
      tokenAddresses: facetAssetList.map((item) => item.address),
    });

    const tokenSwapLastPrice = await services.facet.getLastSwapPrice({
      token_addresses: facetAssetList.map((item) => item.address),
      eth_contract_address: collectionDetail.data.collections.facetStat.ethAddress,
      router_address: collectionDetail.data.collections.facetStat.routerAddress,
    });

    let tokenInfoObj: { [key in string]: ISwapTokenInfoItem } = {};

    tokenInfo.data.tokens.forEach((item) => {
      tokenInfoObj[ethers.utils.getAddress(item.contractAddress)] = item;
    });

    let tokenSwapLastPriceObj: { [key in string]: SwapLastPriceItem } = {};

    tokenSwapLastPrice.result.forEach((item) => {
      tokenSwapLastPriceObj[ethers.utils.getAddress(item.token_address)] = item;
    });

    facetAssetList.map((item) => {
      const decimals = tokenInfoObj[ethers.utils.getAddress(item.address)].decimals;
      const priceUsd = new BigNumber(tokenInfo.data.ethPrice)
        .multipliedBy(
          ethers.utils.formatUnits(tokenSwapLastPriceObj[ethers.utils.getAddress(item.address)].last_swap_price),
        )
        .toString(10);
      console.log(priceUsd, item.balance);
      assetList.push({
        name: item.name,
        symbol: item.symbol,
        priceUsd,
        amount: item.balance,
        valueUsd: new BigNumber(priceUsd).multipliedBy(item.balance).toString(10),
        category: 'facet',
        contractAddress: item.address,
        icon: '',
        decimals,
        bridgedToken: tokenInfoObj[ethers.utils.getAddress(item.address)].bridgedToken,
      });
    });

    if (etchAssset.code == 200 && etchAssset.data.collections.length > 0) {
      etchAssset.data.collections
        .filter((item) => new BigNumber(item.verifiedAmt).gt(0))
        .map((item) => {
          assetList.push({
            name: item.collectionName,
            symbol: item.collectionName.split(' ')[1],
            priceUsd: String(item.floorPrice),
            amount: String(item.verifiedAmt),
            valueUsd: String(item.value),
            category: 'ethereum',
            contractAddress: '',
            icon: item.icon,
          });
        });
    }

    return assetList;
  }
}
