import ApiClient from '@/services/network/ApiClient';
import { AuthVerifyRequest, AuthVerifyResponse, GetAuthNonceResponse, UserInfoDataResponse } from './types';

export default class MarketplaceService {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getAuthNonce(address: string): Promise<GetAuthNonceResponse> {
    return await this.apiClient.get<GetAuthNonceResponse>(`/did/auth/nonce?address=${address}`);
  }

  async authVerify(data: AuthVerifyRequest): Promise<AuthVerifyResponse> {
    return await this.apiClient.post<{}, AuthVerifyResponse>(`/did/auth/verify`, data);
  }

  async getUserInfo(address: string): Promise<UserInfoDataResponse> {
    return await this.apiClient.get<UserInfoDataResponse>(`/did/${address}`);
  }
}
