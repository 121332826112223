import { ethers } from 'ethers';
import { getEtchMarketSweepContract } from './contracts';
import { GetOrderByCartData } from '../marketpalce/types';
import { BatchOrder, OrderTypes } from '@/typechain-types/contracts/EtchMarket';
import { INTERNAL_Snapshot } from 'valtio';

export const batchBuyEthscription = async ({
  singer,
  ethscriptionSweep,
  sellPrice,
}: {
  singer: ethers.providers.JsonRpcSigner;
  ethscriptionSweep: INTERNAL_Snapshot<GetOrderByCartData>;
  sellPrice: string;
}): Promise<ethers.ContractReceipt> => {
  if (!singer?.provider) throw Error('not singer');

  const contract = await getEtchMarketSweepContract(ethscriptionSweep.sweepAddress, singer);

  const orderList: BatchOrder.EthscriptionOrderStruct[] = ethscriptionSweep.orders
    .map((item) => item.order)
    .filter((item) => item && item.orderHash.trim() !== '')
    .map((item) => {
      return {
        ...item,
        ethscriptionIds: [item.ethscriptionId],
        quantities: [item.quantity],
        r: item.signature.slice(0, 66),
        s: '0x' + item.signature.slice(66, 130),
        v: parseInt(item.signature.slice(130, 132), 16),
      };
    });

  const merkleTree = ethscriptionSweep.orders
    .map((item) => item.order)
    .filter((item) => item && item.orderHash.trim() !== '')
    .map((item) => {
      return {
        root:
          item.merkleRoot.trim() == ''
            ? '0x0000000000000000000000000000000000000000000000000000000000000000'
            : item.merkleRoot,
        proof: item.merkleProof,
      };
    }) as BatchOrder.MerkleTreeStruct[];

  // const tx = await sweep.([newOrder], [merkleTree], {value: sellPrice})
  // const transactionResponse = await contract.batchBuy(orderList, { value: sellPrice });
  const transactionResponse = await contract.bulkBuy(orderList, merkleTree, { value: sellPrice });

  const transaction = await transactionResponse.wait();

  return transaction;
};
