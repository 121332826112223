import { FACET_CONFIG } from '@/constants/config';
import ApiClient from './ApiClient';
import axios from 'axios';

const facetClient = new ApiClient(
  axios.create({
    baseURL: FACET_CONFIG.SCAN_URL,
    responseType: 'json' as const,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 10 * 1000,
  }),
);

// facetClient.client.interceptors.request.use(
//   (config) => {
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   },
// );

// facetClient.client.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     return Promise.reject(error);
//   },
// );

export default facetClient;
