import ApiClient from '@/services/network/ApiClient';
import {
  GetVaultEthscriptionRequest,
  GetVaultBalanceRequest,
  GetVaultBalanceResponse,
  GetVaultListRequest,
  GetVaultListResponse,
  GetVaultBalanceDataResponse,
  GetStakingStaticDataResponse,
  GetStakingRankRequest,
  GetStakingRankDataResponse,
  GetStakingRecordRequest,
  GetStakingRecordDataResponse,
  PostVaultRedeemRequest,
  PostVaultRedeemDataResponse,
  GetVaultWidthdrawDataResponse,
  GetVaultWidthdrawData,
} from './types';
import { getQueryParams } from '../getQueryParams';
import delay from '@/utils/delay';

export default class VaultService {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getVaultList(data: GetVaultListRequest): Promise<GetVaultListResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetVaultListResponse>(`/vaults/ethscriptions?${queryParams}`);
  }

  async getVaultBalance(data: GetVaultBalanceRequest): Promise<GetVaultBalanceResponse> {
    const { address, ...props } = data;
    const queryParams = getQueryParams(props as any);
    return await this.apiClient.get<GetVaultBalanceResponse>(`/vaults/balance/${address}?${queryParams}`);
  }

  async getVaultEthscription(data: GetVaultEthscriptionRequest): Promise<GetVaultBalanceDataResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetVaultBalanceDataResponse>(`/vaults/ethscriptions/split?${queryParams}`);
  }

  async getStakingStatic(collection: string): Promise<GetStakingStaticDataResponse> {
    return await this.apiClient.get<GetStakingStaticDataResponse>(`/staking/statistics/${collection}`);
  }

  async getStakingRank(data: GetStakingRankRequest): Promise<GetStakingRankDataResponse> {
    const { collection, ...props } = data;
    const queryParams = getQueryParams(props as any);
    return await this.apiClient.get<GetStakingRankDataResponse>(`/staking/ranking/${collection}?${queryParams}`);
  }

  async getStakingRecord(data: GetStakingRecordRequest): Promise<GetStakingRecordDataResponse> {
    const { owner, ...props } = data;
    const queryParams = getQueryParams(props as any);
    return await this.apiClient.get<GetStakingRecordDataResponse>(`/staking/records/${owner}?${queryParams}`);
  }

  async postVaultRedeem(data: PostVaultRedeemRequest): Promise<PostVaultRedeemDataResponse> {
    return await this.apiClient.post<{}, PostVaultRedeemDataResponse>('/vaults/ethscriptions/redeem', data);
  }

  async getVaultWithdraw(orderHash: string): Promise<GetVaultWidthdrawData | undefined> {
    const queryParams = getQueryParams({ orderHash });
    let isRequest = true;

    while (isRequest) {
      const response = await this.apiClient.get<GetVaultWidthdrawDataResponse>(
        `/vaults/ethscriptions/withdraw?${queryParams}`,
      );

      if (response?.code == 200) {
        if (response.data.trustedSignature.trim() != '') {
          isRequest = false;
          return response.data;
        } else {
          await delay(2000);
        }
      } else {
        throw Error(response.message);
      }
    }
  }
}
