import ApiClient from '@/services/network/ApiClient';
import {
  GetMarketListedRequest,
  GetMarketListedResponse,
  GetMyEthscriptionsRequest,
  GetMyEthscriptionsResponse,
  GetOrderDataResponse,
  GetOrderNonceDataResponse,
  ListingRequest,
  ListingResponse,
  GetCollectionListRequest,
  GetCollectionListReponse,
  GetHistoryOrderRequest,
  GetHistoryOrderReponse,
  GetCollectionOwnerListRequest,
  GetCollectionOwnerListReponse,
  GetWithdrawDataResponse,
  GetEthscribeRequest,
  GetEthscribeDataReponse,
  GetOrderNonceRequest,
  GetEthPriceDataReponse,
  GetCollectionDetailRequest,
  GetCollectionDetailReponse,
  GetOrderByCartDataResponse,
  GetOrderByCartData,
  GetEthscriptionAssetResponse,
  GetEthscriptionActivityResponse,
  ListingBulkRequest,
  GetWithdrawDataBulkResponse,
  MarketChartRequest,
  MarketChartResponse,
  GetSwapActivityRequest,
  GetSwapActivityResponse,
  GetSwapTokensInfoResponse,
  GetSwapTokensInfoRequest,
} from './types';
import { getQueryParams } from '../getQueryParams';

export default class MarketplaceService {
  private apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async getMarketList(data: GetMarketListedRequest): Promise<GetMarketListedResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetMarketListedResponse>(`/markets/ethscriptions/listed?${queryParams}`);
  }

  async getMyEthscriptions(data: GetMyEthscriptionsRequest): Promise<GetMyEthscriptionsResponse> {
    const { owner, ...props } = data;
    const queryParams = getQueryParams(props as any);
    return await this.apiClient.get<GetMyEthscriptionsResponse>(
      `/markets/ethscriptions/address/${owner}?${queryParams}`,
    );
  }

  async getWithdrawBulk(ethscriptionIds: string[]): Promise<GetWithdrawDataBulkResponse> {
    return await this.apiClient.post<{ ethscriptionIds: string[] }, GetWithdrawDataBulkResponse>(
      `/markets/ethscriptions/withdraw/bulk`,
      {
        ethscriptionIds,
      },
    );
  }

  async getOrderNonce(data: GetOrderNonceRequest): Promise<GetOrderNonceDataResponse> {
    const { address, ...props } = data;
    const queryParams = getQueryParams(props as any);
    return await this.apiClient.get<GetOrderNonceDataResponse>(`/markets/order/metadata/${address}?${queryParams}`);
  }

  async getWithdraw(ethscriptionId: string): Promise<GetWithdrawDataResponse> {
    return await this.apiClient.get<GetWithdrawDataResponse>(`/markets/ethscriptions/withdraw/${ethscriptionId}`);
  }

  async getOrderID(orderId: string): Promise<GetOrderDataResponse> {
    return await this.apiClient.get<GetOrderDataResponse>(`/markets/order/${orderId}`);
  }

  async getOrderIDByCart(orderIds: string[]): Promise<GetOrderByCartData | null> {
    const response = await this.apiClient.post<{}, GetOrderByCartDataResponse>('/markets/cart/orders', {
      orderIds,
    });

    const efficientList: GetOrderByCartData['orders'] = [];
    const invalidList: GetOrderByCartData['orders'] = [];

    if (response?.code == 200) {
      response.data.orders.forEach((item, index) => {
        // if (index == 0) {
        //   item.order.signature = '';
        // }
        if (item.order.signature.trim() == '') {
          invalidList.push(item);
        } else {
          efficientList.push(item);
        }
      });

      response.data.orders = efficientList.concat(invalidList);

      return response.data;
    }

    return null;
  }

  async addListing(data: ListingRequest): Promise<ListingResponse> {
    return await this.apiClient.post<{}, ListingResponse>(`/markets/ethscriptions/listing`, data);
  }

  async addListingBulk(data: ListingBulkRequest): Promise<ListingResponse> {
    return await this.apiClient.post<{}, ListingResponse>(`/markets/ethscriptions/bulk_listing`, data);
  }

  async editListing(data: ListingRequest): Promise<ListingResponse> {
    return await this.apiClient.post<{}, ListingResponse>(`/markets/ethscriptions/edit/listing`, data);
  }

  async marketChart(data: MarketChartRequest): Promise<MarketChartResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<MarketChartResponse>(`/markets/market_chart?${queryParams}`);
  }

  async getCollectionList(data: GetCollectionListRequest): Promise<GetCollectionListReponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetCollectionListReponse>(`/markets/collections?${queryParams}`);
  }

  async getCollectionOwnerList(data: GetCollectionOwnerListRequest): Promise<GetCollectionOwnerListReponse> {
    const { address, ...props } = data;
    const queryParams = getQueryParams(props as any);
    return await this.apiClient.get<GetCollectionOwnerListReponse>(
      `/markets/ethscriptions/collections/${address}?${queryParams}`,
    );
  }

  async getCollectionDetail(data: GetCollectionDetailRequest): Promise<GetCollectionDetailReponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetCollectionDetailReponse>(`/markets/collections/details?${queryParams}`);
  }

  async getHistoryOrder(data: GetHistoryOrderRequest): Promise<GetHistoryOrderReponse> {
    const { address, ...props } = data;
    const queryParams = getQueryParams(props as any);
    if (address) {
      return await this.apiClient.get<GetHistoryOrderReponse>(`/markets/history/orders/${address}?${queryParams}`);
    }
    return await this.apiClient.get<GetHistoryOrderReponse>(`/markets/history/orders?${queryParams}`);
  }

  async getEthscribe(data: GetEthscribeRequest): Promise<GetEthscribeDataReponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetEthscribeDataReponse>(`/markets/ethscriptions/ethscribe?${queryParams}`);
  }

  async getEthPrice(): Promise<GetEthPriceDataReponse> {
    return await this.apiClient.get<GetEthPriceDataReponse>(`/markets/currencies/ethprice`);
  }

  async getEtherscriptionAsset(ethscriptionIdOrNumber: string): Promise<GetEthscriptionAssetResponse> {
    return await this.apiClient.get<GetEthscriptionAssetResponse>(`/ethscriptions/asset/${ethscriptionIdOrNumber}`);
  }

  async getEtherscriptionActivity(ethscriptionId: string): Promise<GetEthscriptionActivityResponse> {
    return await this.apiClient.get<GetEthscriptionActivityResponse>(`/ethscriptions/activity/${ethscriptionId}`);
  }

  async getSwapActivity(data: GetSwapActivityRequest): Promise<GetSwapActivityResponse> {
    const queryParams = getQueryParams(data as any);
    return await this.apiClient.get<GetSwapActivityResponse>(`/markets/history/swaps?${queryParams}`);
  }

  async getSwapTokensInfo(data: GetSwapTokensInfoRequest): Promise<GetSwapTokensInfoResponse> {
    return await this.apiClient.post<{}, GetSwapTokensInfoResponse>(`/markets/swap/tokens`, data);
  }
}
